import { Component } from 'preact';
import classNames from 'classnames';
import PropTypes from 'prop-types';

export default class PortalUsedVehiclesCardV2 extends Component {
  makePriceDisplay(price) {
    return new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    }).format(price);
  }

  formatted_price() {
    return this.makePriceDisplay(this.props.price);
  }

  formatted_oldPrice() {
    return this.makePriceDisplay(this.props.oldPrice);
  }

  render() {
    const {
      itemImage,
      model,
      version,
      name,
      moduleLink,
      slug,
      oldPrice,
      km,
      exchange,
      kind,
      fabricationYear,
      modelYear,
      itemUnit,
      isOnFirstPage,
      price,
    } = this.props;

    return (
      <div className="card card__used-vehicle-v2">
        <div className="card__header">
          <a
            className="card__image-link"
            href={`${moduleLink}/${slug}`}
            aria-label={`Link para ${name}`}
          >
            {itemImage ? (
              <img
                className="card__image"
                src={itemImage}
                alt={name}
                width="260"
                height="180"
                // eslint-disable-next-line react/no-unknown-property
                fetchpriority={isOnFirstPage ? 'high' : 'low'}
                loading={isOnFirstPage ? 'auto' : 'lazy'}
              />
            ) : (
              <div
                className={classNames('card__image-value', {
                  'default-bg__motorcycle_middle-default': kind === 'Moto',
                  'default-bg__model-middle-default': kind !== 'Moto',
                })}
              />
            )}
          </a>
        </div>
        <div className="card__content">
          <div className="card__content-text-info">
            <p className="card__title">{model}</p>
            <p className="card__subtitle">{version}</p>
          </div>

          <div className="card__content-price-info">
            <p className="card__old-price">{!!oldPrice && this.formatted_oldPrice()}</p>
            <p className="card__price">{!!price && this.formatted_price()}</p>
          </div>
          <div className="card__content-details">
            <div className="card__content-details__line">
              <div className="card__content-details__line-item">
                <i className="icon icon-km"></i>
                <p className="list-item__info">{km}km</p>
              </div>
              <div className="card__content-details__line-item">
                <i className="icon icon-transmission"></i>
                <p className="list-item__info">{exchange}</p>
              </div>
            </div>
            <div className="card__content-details__line">
              <div className="card__content-details__line-item">
                <i className="icon icon-calendar"></i>
                <p className="list-item__info">
                  {fabricationYear} / {modelYear}
                </p>
              </div>
              <div className="card__content-details__line-item">
                <i className="icon icon-map-pin"></i>
                <p className="list-item__info item-unit">{itemUnit.name}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

PortalUsedVehiclesCardV2.defaultProps = {
  moduleLink: '',
  itemImage: '',
  model: '',
  version: '',
  oldPrice: '',
  price: '',
  slug: '',
  km: '',
  kind: '',
  exchange: '',
  modelYear: '',
  fabricationYear: '',
  itemUnit: '',
  showPrice: true,
  lazy: false,
  isOnFirstPage: false,
};

PortalUsedVehiclesCardV2.propTypes = {
  moduleLink: PropTypes.string,
  itemImage: PropTypes.string,
  model: PropTypes.string,
  version: PropTypes.string,
  oldPrice: PropTypes.number,
  price: PropTypes.number,
  slug: PropTypes.string,
  km: PropTypes.number,
  exchange: PropTypes.string,
  kind: PropTypes.string,
  modelYear: PropTypes.number,
  fabricationYear: PropTypes.number,
  itemUnit: PropTypes.shape({}),
  showPrice: PropTypes.bool,
  lazy: PropTypes.bool,
  isOnFirstPage: PropTypes.bool,
};
